// |------------------------------------------------------
// |------------------------------------------------------
// | Default states
// |------------------------------------------------------
// |------------------------------------------------------

@if $gridle-default-states {
	// retina
	@include gridle_register_state("retina", (
		query : "(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi), (min-resolution: 2dppx)",
		classes : false
	) );

	// tv
	@include gridle_register_state("tv", (
		query : "only tv",
		classes : false
	) );

	// print
	@include gridle_register_state("print", (
		query : "only print",
		classes : false
	) );

	// portrait
	@include gridle_register_state("portrait", (
		query : "only screen and (orientation: portrait)",
		classes : false
	) );

	// landscape
	@include gridle_register_state("landscape", (
		query : "only screen and (orientation: landscape)",
		classes : false
	) );
}