//
// Row
//
@mixin _gridle_common_row(
	$state : default
) {
	// For modern browser
	&:before,
	&:after {
		content:"";
		display:table;
	}
	&:after {
		clear:both;
	}
	// For IE 6/7 (trigger hasLayout
	& {
	   zoom:1;
	}
}
@mixin gridle_row(
	$reverse : false
) {
	@include _gridle_call(row) {
		padding-left: 0 !important;
		padding-right: 0 !important;
		margin-left: -#{gridle_get_state_var(gutter-left)};
		margin-right: -#{gridle_get_state_var(gutter-right)};
		// font-size:0; // to avoid problems with inline-block margins

		@if $reverse {
			transform : rotateY(180deg);

			> #{_gridle_get_generic_selector(grid)} {
				transform : rotateY(-180deg);
			}
		}

		#{_gridle_get_generic_selector(no-gutter)} > & {
			margin-left: 0 !important;
			margin-right: 0 !important;
		}

		// do this only when not in generate phase
		// mean that we use the mixin directly
		@if _gridle_is_in_generate_phase() == false {
			@each $stateName, $state in gridle_get_states() {
				@include gridle_state($state) {
					& #{str-replace(unquote("#{&}"),".state-#{gridle_get_state_var(name, $state)}","")} {
						margin-left: -#{gridle_get_state_var(gutter-left, $state)};
						margin-right: -#{gridle_get_state_var(gutter-right, $state)};
					}
				}
			}
		}
	}
}


//
// Col
//
@mixin _gridle_common_col(
	$state : default
) {
}
@mixin gridle_col(
	$reverse : false
) {
	@include _gridle_call(col) {
		padding-left: 0 !important;
		padding-right: 0 !important;
		margin-left: -#{gridle_get_state_var(gutter-left)};
		margin-right: -#{gridle_get_state_var(gutter-right)};
		// font-size:0; // to avoid problems with inline-block margins

		@if $reverse {
			transform : rotateX(180deg);

			> #{_gridle_get_generic_selector(grid)} {
				transform : rotateX(-180deg);
			}
		}

		// do this only when not in generate phase
		// mean that we use the mixin directly
		@if _gridle_is_in_generate_phase() == false {
			@each $stateName, $state in gridle_get_states() {
				@include gridle_state($state) {
					& #{str-replace(#{&},".state-#{gridle_get_state_var(name, $state)}","")} {
						margin-left: -#{gridle_get_state_var(gutter-left, $state)};
						margin-right: -#{gridle_get_state_var(gutter-right, $state)};
					}
				}
			}
		}
	}
}


//
// Nowrap
//
@mixin _gridle_common_nowrap(
	$state : default
) {
	white-space: nowrap;

	> * {
		white-space: normal;
	}
}
@mixin gridle_nowrap() {
	@include _gridle_call(nowrap) {
	}
}


//
// Wrap
//
@mixin _gridle_common_wrap(
	$state : default
) {
	white-space:normal;
}
@mixin gridle_wrap() {
	@include _gridle_call(wrap) {
	}
}


//
// Grid
//
@mixin _gridle_common_grid(
	$state : default
) {
	// vars
	$direction : gridle_get_state_var(direction, $state);

	// css
	display:inline-block;
	min-height:1px;
	@if $direction == rtl {
		float:right;
	} @else {
		float:left;
	}
	@if $gridle-vendor-prefix {
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
	}
	// font-size:1rem; // reset the font size
	box-sizing: border-box;
	vertical-align: top;
	@include gridle_gutter();

	// handle direction attribute
	@if gridle_get_state_var(dir-attribute, $state) {
		[dir="rtl"] & {
			float: right;
		}
		[dir="ltr"] & {
			float: left;
		}
	}

}
@mixin gridle_grid(
	$columns,
	$context : null
) {
	@if $columns == grow {
		@include gridle_grid_grow();
	} @else if $columns == adapt {
		@include gridle_grid_adapt();
	} @else {

		@include _gridle_call(grid) {

			// vars
			$name : gridle_get_state_var(name);
			@if type-of($context) != number {
				$context : gridle_get_state_var(context);
			}
			$name-multiplicator : gridle_get_state_var(name-multiplicator);
			$gutter-left : gridle_get_state_var(gutter-left);
			$gutter-right : gridle_get_state_var(gutter-right);
			$column-width : gridle_get_state_var(column-width);

			// manage columns
			@if type-of($columns) != number and gridle_has_column($columns) {
				// the columns is a saved one, get the context and column value
				$column : map-get($_gridle_columns, $columns);
				$context : map-get($column, context);
				$columns : map-get($column, columns);
			} @else if type-of($columns) == number {
				// check if we have a ratio
				@if $columns < 1 {
					$columns : $context / 100 * ($columns * 100);
				}
				$columns : $columns / $name-multiplicator;
			} @else {
				@error "the column #{$columns} does not exist...";
			}

			// width :
			$width : 0;
			@if $column-width {
				$width : $column-width * $columns;
			} @else {
				$width : percentage(1 / $context * $columns);
			}

			// set value :
			width:$width;
		}
	}
}


//
// Grid table
//
@mixin _gridle_common_grid_table(
	$state : default
) {
}
@mixin gridle_grid_table(
) {
	@include _gridle_call(grid-table) {
		display: table-cell;
		float: none;
		vertical-align: top;
	}
}


//
// Row full
//
@mixin _gridle_common_row_full(
	$state : default
) {
}
@mixin gridle_row_full(
) {
	@include _gridle_call(row-full) {
		$direction : gridle_get_state_var(direction);
		width: 100vw;
		@if $direction == ltr {
			margin-left:50% !important;
			margin-right:0 !important;
			@if $gridle-vendor-prefix {
				-webkit-transform: translateX(-50%);
				-moz-transition: translateX(-50%);
				-ms-transform: translateX(-50%);
				-o-transform: translateX(-50%);
			}
			transform: translateX(-50%);
		} @else {
			margin-right:50% !important;
			margin-left:0 !important;
			@if $gridle-vendor-prefix {
				-webkit-transform: translateX(50%);
				-moz-transition: translateX(50%);
				-ms-transform: translateX(50%);
				-o-transform: translateX(50%);
			}
			transform: translateX(50%);
		}
		@if gridle_get_state_var(dir-attribute) {
			[dir="ltr"] & {
				margin-left:50% !important;
				margin-right:0 !important;
				@if $gridle-vendor-prefix {
					-webkit-transform: translateX(-50%);
					-moz-transition: translateX(-50%);
					-ms-transform: translateX(-50%);
					-o-transform: translateX(-50%);
				}
				transform: translateX(-50%);
			}
			[dir="rtl"] & {
				margin-right:50% !important;
				margin-left:0 !important;
				@if $gridle-vendor-prefix {
					-webkit-transform: translateX(50%);
					-moz-transition: translateX(50%);
					-ms-transform: translateX(50%);
					-o-transform: translateX(50%);
				}
				transform: translateX(50%);
			}
		}
	}
}


//
// grid adapt
//
@mixin _gridle_common_grid_adapt(
	$state : default
) {
	display: table-cell;
	width:1px;
	white-space:nowrap !important;
	@if $gridle-vendor-prefix {
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
	}
	box-sizing: border-box;
	@include gridle_gutter();
}
@mixin gridle_grid_adapt(
) {
	@include _gridle_call(grid-adapt) {
	}
}


//
// Grow
//
@mixin _gridle_common_grid_grow(
	$state : default
) {
	display: table-cell;
	width:99999px;
	@if $gridle-vendor-prefix {
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
	}
	box-sizing: border-box;
	@include gridle_gutter();

}
@mixin gridle_grid_grow(
) {
	@include _gridle_call(grid-grow) {
	}
}


//
// Order
//
@mixin _gridle_common_order(
	$state : default
) {
}
@mixin gridle_order(
	$position
) {
	@include _gridle_call(order) {
	}
}


//
// Clear each
//
@mixin _gridle_common_clear_each(
	$state : default
) {
}
@mixin gridle_clear_each(
	$clearEach,
	$clearWhat : both
) {
	@include _gridle_call(clear-each) {
		$grid : map-get($_gridle-packages, grid);
		$sel : map-get($grid, generic-selector);
		@if not $sel {
			$sel : _gridle_get_generic_selector(grid);
		}

		> #{$sel}:nth-of-type(#{$clearEach}n+1) {
			clear : $clearWhat;
		}
	}
}


//
// Align :
//
@mixin _gridle_common_row_align(
	$state : default
) {
}

@mixin gridle_row_align(
	$align : middle
) {
	@include _gridle_call(row-align) {
		// need to change the way grids items are layed out
		@if in-map($align, top) of in-map($align, middle) or in-map($align, bottom) {
			font-size:0;
			clear:both;

			> * {
				float:none !important;
				@if in-map($align, top) {
					vertical-align:top;
				} @elseif in-map($align, middle) {
					vertical-align:middle;
				} @else {
					vertical-align:bottom;
				}
				font-size:1rem;
				@-moz-document url-prefix() {
					& {
						margin-right: -0.5px; // fix firefox weird issue
					}
				}
			}
		}

		@each $a in $align {
			@if $a == left {
				text-align: left;
			} @else if $a == center {
				text-align: center;
			} @else if $a == right {
				text-align: right;
			} @else if $a == top {
				vertical-align: top;
			} @else if $a == middle {
				vertical-align: middle;
			} @else if $a == bottom {
				vertical-align: bottom;
			}
		}

		@if in-map($align, center) or in-map($align, right) {
			// restore text align of the grid items
			> * {
				text-align: left;
			}
		}
	}
}
