// |------------------------------------------------------
// |------------------------------------------------------
// @created 	25.03.13
// @updated 	27.06.16
// @author 		Olivier Bossel <olivier.bossel@gmail.com>
// @version 	2.0.48
// |------------------------------------------------------
// |------------------------------------------------------




// |------------------------------------------------------
// |------------------------------------------------------
// | Version
// |------------------------------------------------------
// |------------------------------------------------------

$_gridle-version : "2.0.48";




// |------------------------------------------------------
// |------------------------------------------------------
// | Variables
// |------------------------------------------------------
// |------------------------------------------------------

$gridle-debug : true;
$_gridle-names-tokens : (
	column								: true, // will be the columns names
	column-count 						: true, // will represent the column index (1,2,3,...)
	align 								: left center right top middle bottom around between,
	float 								: left right both,
	count 								: true, // will represent a certain count
	side 								: top right bottom left null,
	reverse 							: '' reverse,
	state 								: true // is a valid pattern
);
$_gridle-names-separators : ('-','--','_','__','@');	// all the allowed separators
$_gridle_is_in_generate_phase : false; 			// keep track if we are in generate phase or not anymore
$_gridle_inited : false;						// keep track if gridle has been inited (silent classes, etc..) or not
$_gridle_settings : (); 						// the default settings
$_gridle_states : ();							// the variable map for each states
$_gridle_clear_classes : (); 					// store each automatic clear count
$_gridle_columns : (); 							// store the registered special columns
$_gridle_extend_base_classes : (); 				// save the rules that will extend the default gridle onces
$_gridle_apply_css_for : (); 					// save the rules that will be applied for each states on a specified gridle mixin type

$_gridle_current_state : ();					// save the current state
$_gridle_current_stateName : default; 			// save the current state name
$_gridle_previous_state : (); 					// save the previous state
$_gridle_previous_stateName : default; 			// save the previous state name

$_content_arg_state : null; 					// this variable is used to pass state through global variable into an @content

$gridle-vendor-prefix : true; 					// set if need to add vendor prefixes when needed

$gridle-scope : (); 					        // Variable scope used in mixin like `gridle_generate_custom_class`



// |------------------------------------------------------
// |------------------------------------------------------
// | Vendors
// |------------------------------------------------------
// |------------------------------------------------------

@import 'flexbox';




// |------------------------------------------------------
// |------------------------------------------------------
// | Settings
// |------------------------------------------------------
// |------------------------------------------------------

@import 'settings';




// |------------------------------------------------------
// |------------------------------------------------------
// | Functions
// |------------------------------------------------------
// |------------------------------------------------------

@import 'functions';




// |------------------------------------------------------
// |------------------------------------------------------
// | Settings mixins
// |------------------------------------------------------
// |------------------------------------------------------

@import 'settings-mixins';




// |------------------------------------------------------
// |------------------------------------------------------
// | Mixins
// |------------------------------------------------------
// |------------------------------------------------------

@import 'mixins';




// |------------------------------------------------------
// |------------------------------------------------------
// | Api
// |------------------------------------------------------
// |------------------------------------------------------

@import 'api';




// |------------------------------------------------------
// |------------------------------------------------------
// | Generate mixins
// |------------------------------------------------------
// |------------------------------------------------------

@import 'generate-mixins';




// |------------------------------------------------------
// |------------------------------------------------------
// | Default states
// |------------------------------------------------------
// |------------------------------------------------------

@import 'default-states';
