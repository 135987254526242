// Import grid settings :
@import '../site_settings';
@import 'grid-settings';


// Generate custom states :
@include gridle_register_state( xs, (
    max-width : $xs
) );
@include gridle_register_state( sm , (
    min-width : $sm,
    gutter-width : $gutter_sm
) );
@include gridle_register_state( md, (
    min-width : $md,
    gutter-width : $gutter_md
) );
@include gridle_register_state( lg, (
    min-width : $lg,
    gutter-width : $gutter_lg
) );
@include gridle_register_state( xlg, (
    min-width : $xlg,
    gutter-width : $gutter_xlg
) );
@include gridle_register_state( xxlg, (
    min-width : $xxlg,
    gutter-width : $gutter_xxlg
) );



// Generate classes :
@include gridle_generate_classes();

// @include gridle_generate_classes(default);   // default is the base state always registered
// @include gridle_generate_classes(mobile);    // only the mobile state
// @include gridle_generate_classes(all, -helpers); // all states, all packages but the helpers

// generate a center custom class for all the states :
// @include gridle_generate_custom_class( ('center','@','%state') ) {
//  text-align:center;
// }
// this will produces classes : center, center@mobile, center@tablet, center@ipad-landscape
// for separators, you can use -, --, _, __ or @

// [class*="gr-"] {
//     @include gridle_set((
//         xs : (
//             grid : 12,
//             push : 0,
//             pull : 0,
//             prefix : 0,
//             suffix : 0
//         )
//     ));
// }